import parse from 'html-react-parser';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { formatText } from '../../helpers/utils';
import Box from '../elements/box';
import ImageElement from '../elements/image-element';

const Footer = ({ footerWidget }) => {
  const theme = useTheme();
  const { components } = footerWidget || {};
  const imageComponents = components?.filter(c => c.component_type === 'IMAGE');
  const otherComponents = components?.filter(c => c.component_type !== 'IMAGE');

  if (!footerWidget) {
    return null;
  }

  const getComponentStyle = component => {
    const { alignment, font } = component;
    return {
      textAlign: formatText(alignment || 'CENTER'),
      fontSize: font?.size || 14,
      color: font?.color || theme.colors.black_text,
    };
  };

  return (
    <FooterWrapper className="flex-column flex-1">
      <Box className="footer-box flex-column flex-1 items-center row-gap-4">
        {otherComponents?.map(component => (
          <label key={component.id} className="regular-text font-16" style={getComponentStyle(component)}>
            {parse(component.name)}
          </label>
        ))}
        <div className="flex flex-1 w-full items-center justify-center col-gap-4 mt-2 footer-image-container">
          {imageComponents?.map(({ id, image, height, width }) => (
            <ImageElement key={id} src={image?.image?.url} height={height || 120} width={width || 240} />
          ))}
        </div>
      </Box>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  .footer-box {
    padding: 24px 72px;
  }
  @media (max-width: 750px) {
    .footer-box {
      padding: 24px 40px;
    }
    .footer-image-container {
      flex-direction: column;
      row-gap: 24px;
    }
  }
`;

export default Footer;
